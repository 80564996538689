import Index from "./Routes/Index";
import Demo from "./Pages/Demo";

function App() {
  //states or hooks

  return (
    <div>
      <Index />
      {/* <Demo /> */}
    </div>
  );
}

export default App;
